import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../settings.service';
import { StaggerAnimation } from 'src/animations';

@Component({
  selector: 'rft-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.scss'],
  animations: StaggerAnimation.animations
})
export class LeaguesComponent implements OnInit {
  animatedListTotalLength = -1;
  isFilterOpen: boolean = false;
  isLeagueList: boolean = false;
  isPrizeZoneLoaded: boolean = false;
  leaguesList: any = [];
  constructor(private _settingsService: SettingsService,private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getLeagues();
  }
  public getLeagues() {
    this.animatedListTotalLength = -1;
    this.isPrizeZoneLoaded = false;
    this._settingsService.getLeagueList().subscribe(res => {
      console.log(res.data.items);
      this.leaguesList = res.data.items;
      this.isLeagueList = true;
      this.isPrizeZoneLoaded = true;
      this.animatedListTotalLength = this.leaguesList?.length;
    }, error => {
      this.toastr.error(error.message, 'RFT NOTIFICATION');
    })
  }
  public leagueDetails(){
    this.router.navigate(['/settings/league-details'])
  }
  openFilter() {
    this.isFilterOpen = true;
  }
  clearFilter() {
    this.isFilterOpen = false;
  }
  applyFilter() {
    this.isFilterOpen = false;
  }
}
