import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../settings.service';
import { StaggerAnimation } from 'src/animations';
declare var bootstrap: any
@Component({
  selector: 'rft-prize-zones',
  templateUrl: './prize-zones.component.html',
  styleUrls: ['./prize-zones.component.scss'],
  animations: StaggerAnimation.animations
})
export class PrizeZonesComponent implements OnInit {
  animatedListTotalLength = -1;
  @ViewChild('closePrizezoneModal') modalClose: any;
  @ViewChild('openPrizezoneModal') modalOpen: any;
  isFilterOpen: boolean = false;
  isPrizezoneView: boolean = false;
  isGetPrizeZoneData: boolean = false;
  prizezones: any = [];
  prizeZoneName: any;
  countriesDropdownList: any = [];
  effectiveCurrencyList: any = [];
  paymentGateWaysList: any = [];
  payoutGateWaysList: any = [];
  viewCountriesDropdownList: any = [];
  dropdownSettings: IDropdownSettings = {};
  leaguesDropdownlist: any = [];
  addPrizeZoneBtnClicked: boolean = false;
  isPrizeZoneLoaded: boolean = false;
  public prizeZoneForm!: FormGroup;
  selectedEffectiveCurrency: any;
  selectedPaymentGateway: any;
  selectedPayoutGateway: any;
  isGetPrizezoneByIdLoaded: boolean = false;
  isNoDataFound: boolean = false;
  public account_validation_messages = {
    'name': [
      { type: 'required', message: 'This Field is required' },
      { type: 'minlength', message: 'Name must be at least 6 characters long' },
      { type: 'maxlength', message: 'Name cannot be more than 50 characters long' },
      { type: 'pattern', message: '<> characters are not allowed' }
    ],
  }
  constructor(private _formBuilder: FormBuilder, private _settingsService: SettingsService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
    this.getCountry();
    this.getPrizeZones();
    this.dropdownSettings = {
      idField: 'dialCode',
      textField: 'name',
      limitSelection: -1,
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
    this.prizeZoneForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      season: ['', [Validators.required]],
      effectiveCurrency: ['', [Validators.required]],
      payment: [[''], [Validators.required]],
      payout: [[''], [Validators.required]],
      countries: new FormControl(this.countriesDropdownList, Validators.required),
    });
    this.getPaymentPayoutGateways('payout');
    this.getPaymentPayoutGateways('payment');
  }

  public getCountry() {
    this._settingsService.getCountryList().subscribe(res => {
      console.log(res.data.items);
      this.countriesDropdownList = res.data.items[0]?.countryList;
      this.effectiveCurrencyList = res.data.items[0]?.countryList;
      // this.paymentGateWaysList = res.data.items[0]?.PaymentGateways;
    }, error => {
      this.toastr.error(error.message, 'RFT NOTIFICATION');
    })
  }

  public getPaymentPayoutGateways(gatewayType: any) {
    this._settingsService.getPaymentPayoutGateway(gatewayType).subscribe(res => {
      console.log('payout gateways', res);
      if (gatewayType == 'payment') {
        this.paymentGateWaysList = res.data.items;
      }
      if (gatewayType == 'payout') {
        this.payoutGateWaysList = res.data.items;
      }
    }, error => {
      this.toastr.error(error.message, 'RFT NOTIFICATION');
    })
  }

  createEditPrizeZone() {
    this.addPrizeZoneBtnClicked = true;
    const newCountryList = this.prizeZoneForm.get('countries')?.value.map((x: any) => {
      let sortCode = this.countriesDropdownList.find((element: any) => element.name === x.name).sortCode;
      return { name: x.name, dial_code: x.dialCode, code: sortCode }
    })
    console.log(newCountryList)
    if (this.prizeZoneForm) {
      const payLoad = {
        name: this.prizeZoneForm.get('name')?.value,
        effectiveCurrency: this.prizeZoneForm.get('effectiveCurrency')?.value,
        payment: [this.prizeZoneForm.get('payment')?.value],
        payout: [this.prizeZoneForm.get('payout')?.value],
        countries: newCountryList
      };
      console.log(payLoad)
      this._settingsService.addPrizeZone(payLoad)
        .subscribe(data => {

          console.log(data);
          this.toastr.success(data.message, 'RFT NOTIFICATION');
          this.modalClose.nativeElement.click();
          this.getPrizeZones();
        }, error => {
          this.toastr.error(error.message, 'RFT NOTIFICATION');
        });
    }
  }

  public getPrizeZones() {
    this.animatedListTotalLength = -1;
    this.isPrizeZoneLoaded = false;
    this._settingsService.getPrizeZoneList().subscribe(res => {
      console.log(res.data.items);
      this.prizezones = res.data.items;
      if (this.prizezones?.length < 1) {
        this.isNoDataFound = true;
      }
      this.isPrizeZoneLoaded = true;
      this.animatedListTotalLength = this.prizezones?.length;

    }, error => {
      this.toastr.error(error.message, 'RFT NOTIFICATION');
    })
  }

  public getPrizezoneById(prizezoneId: any) {
    this.isPrizezoneView = true;
    this.isGetPrizezoneByIdLoaded = false;
    this.modalOpen.nativeElement.click();
    this._settingsService.getPrizeZoneById(prizezoneId).subscribe(res => {
      console.log(res);
      this.isGetPrizeZoneData = true;
      this.isGetPrizezoneByIdLoaded = true;
      this.prizeZoneName = res.data.items[0].name;
      this.viewCountriesDropdownList = res.data.items[0].countries;
      this.selectedEffectiveCurrency = res.data?.items[0]?.effectiveCurrency;
      this.selectedPaymentGateway = res.data?.items[0]?.payment;
      this.selectedPayoutGateway = res.data?.items[0]?.payout;
    })
  }

  closePrizeZoneModal() {
    this.modalClose.nativeElement.click();
    this.isPrizezoneView = false;
    this.isGetPrizeZoneData = false;
  }



  openFilter() {
    this.isFilterOpen = true;
  }
  clearFilter() {
    this.isFilterOpen = false;
  }
  applyFilter() {
    this.isFilterOpen = false;
  }
}
