import { trigger, state, style, animate, transition, query, group, stagger, AnimationTriggerMetadata } from '@angular/animations';

export class StaggerAnimation {
  static animations = StaggerAnimation.getAnimations();

  static getAnimations(): Array<AnimationTriggerMetadata> {
      return [
        trigger('pageAnimations', [
          transition(':enter', [
            query('.custom-tbody-tr, form', [
              style({opacity: 0, transform: 'translateY(-100px)'}),
              stagger(-30, [
                animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
              ])
            ])
          ])
        ]),
        trigger('filterAnimation', [
          transition(':enter, * => 0, * => -1', []),
          transition(':increment', [
            query(':enter', [
              style({ opacity: 0, width: '0px' }),
              stagger(50, [
                animate('300ms ease-out', style({ opacity: 1, width: '*' })),
              ]),
            ], { optional: true })
          ]),
          transition(':decrement', [
            query(':leave', [
              stagger(50, [
                animate('300ms ease-out', style({ opacity: 0, width: '0px' })),
              ]),
            ])
          ]),
        ]),
      ];
  }
}