// const hostUrl = "https://cold-planes-hide-14-97-180-234.loca.lt/dffservice"
// const authHostUrl = "https://cold-planes-hide-14-97-180-234.loca.lt/rft-accounts"
const hostUrl = "https://tempdevadminapi.realfantasyteams.com/adminService"
const authHostUrl = "https://tempdevuser.realfantasyteams.com/rft-accounts"
const sffHostUrl = "https://tempdevsff.realfantasyteams.com/sffService"
export const environment = {
  production: false,
  hostUrl: hostUrl,
  sffHostUrl: sffHostUrl,
  openId: {
    accountsUrl: authHostUrl,
    backendUrl:hostUrl,
    clientId: 'GCMUDiuY5a7WvyUNt9n3QztToSHzK7Uj',
    clientSecret:'Jsjw3hsH5WIcS5niwshi5hiss5shIsh',
    redirect_url: `${window.location.origin}/auth/redirect`,
    response_type: 'code',
    opUrl: `${authHostUrl}/auth/authorize`,
    opMobileUrl: `${authHostUrl}/auth/openIdLogin/login`,
    scope: 'openid',
    tokenEndpoint: `${authHostUrl}/auth/openIdLogin/token`,
    logoutEndpoint:`${authHostUrl}/auth/openIdLogin/logout`,
  },
  defaultSeason: 2023
};

// const hostUrl = "https://0a29-136-232-67-206.ngrok.io/dffService"
// const authHostUrl = "https://0a29-136-232-67-206.ngrok.io/rft-accounts"
// export const environment = {
//   production: false,
//   hostUrl: hostUrl,
//   openId: {
//     backendUrl:hostUrl,
//     clientId: 'GCMUDiuY5a7WvyUNt9n3QztToSHzK7Uj',
//     clientSecret:'Jsjw3hsH5WIcS5niwshi5hiss5shIsh',
//     redirect_url: `${window.location.origin}/auth/redirect`,
//     response_type: 'code',
//     opUrl: `${hostUrl}/auth/authorize`,
//     scope: 'openid',
//     tokenEndpoint: `${hostUrl}/auth/token`,
//     logoutEndpoint:`${hostUrl}/auth/logout`,
//   }
// };

