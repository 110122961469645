import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Paginator {
  applyFilter: (filter: Record<string, any>) => void;
  isLoading: boolean;
  data: any;
  $pagination: BehaviorSubject<{ pageNo: number; limit: number }>;
}
export const PAGINATOR = new InjectionToken<Paginator>('Paginator');
