import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrizeZonesComponent } from './core/prize-zones/prize-zones.component';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '../core/guards/user.guard';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CoreDashboardComponent } from './core/core-dashboard/core-dashboard.component';
// import { LeaguesComponent } from './core/leagues/leagues.component';
import { LeaguesComponent } from './league/leagues/leagues.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LeagueDetailsComponent } from './league/league-details/league-details.component';
import { PayoutGatewaysComponent } from './payout-gateway/payout-gateways/payout-gateways.component';
import { PaymentGatewaysComponent } from './payment-gateway/payment-gateways/payment-gateways.component';
import { PayoutGatewayCreateViewComponent } from './payout-gateway/payout-gateway-create-view/payout-gateway-create-view.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CoreModule } from '../core/core.module';

export const routes: Routes = [
  {
    path: 'core',
    component: CoreDashboardComponent,
  },
  {
    path: 'leagues',
    component: LeaguesComponent,
  },
  {
    path: 'league-details',
    component: LeagueDetailsComponent,
  },
  {
    path: 'payout-gateways',
    component: PayoutGatewaysComponent,
  },
  {
    path: 'payout-gateway-create',
    component: PayoutGatewayCreateViewComponent,
  },
  {
    path: 'payment-gateways',
    component: PaymentGatewaysComponent,
  },
  {
    path: '**',
    redirectTo: '/settings/core',
  },
];

@NgModule({
  declarations: [
    PrizeZonesComponent,
    CoreDashboardComponent,
    LeaguesComponent,
    LeagueDetailsComponent,
    PayoutGatewaysComponent,
    PaymentGatewaysComponent,
    PayoutGatewayCreateViewComponent,
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    RouterModule.forChild(routes),
    CoreModule,
  ],
  exports: [RouterModule],
})
export class SettingsModule {}
