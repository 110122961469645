<div class="container-fluid">
  <div class="row mt-3">
    <div class="col fw-bold fs-16">
      <img
        [routerLink]="['/assets/clubs']"
        src="../../../assets/back-arrow.png"
        class="hw-26 cursor-pointer"
        alt=""
      />
      <label class="ps-5"> League Details</label>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card stats-card br-10 border-0">
        <div class="card-body">
          <div class="row">
            <div class="col-1 ps-5 pe-0 jersy-section">
              <img
                class="h-80"
                src="https://s3.amazonaws.com/realfantasyteams/uploads/league/8.png"
                alt=""
              />
            </div>
            <div class="col">
              <p class="player-name fw-bold mt-4 mb-0 black-text">
                <span>
                  British Football League (BFL)
                  <span class="team-name greyText">
                    ( t1 <rft-copy-btn text="t1"></rft-copy-btn>)
                  </span>
                </span>
              </p>
            </div>
            <div class="col align-self-end">
              <div class="row">
                <div class="col-7">
                  <p class="text-secondary">
                    No. of Teams: <span class="fw-bold">12</span>
                  </p>
                </div>
                <div class="col-5">
                  <p class="text-secondary">
                    No. of Players: <span class="fw-bold">312</span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7">
                  <p class="text-secondary">
                    Most Selected Player:
                    <span class="fw-bold">Christiano Ronaldo (€37.49m)</span>
                  </p>
                </div>
                <div class="col-5">
                  <p class="text-secondary">
                    Total Prize Money Given: <span class="fw-bold">€352m</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-8">
      <div class="card stats-card border-0">
        <div class="card-body">
          <div class="row">
            <div class="col-12 border-0 card-height-220 br-10"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card stats-card border-0">
        <div class="card-body">
          <div class="row">
            <div class="col-12 border-0 card-height-220 br-10"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
