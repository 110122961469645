import { Component, Input, Inject } from '@angular/core';
import { PAGINATOR, Paginator } from '../../tokens/pagination.token';

@Component({
  selector: 'rft-pagination-controller',
  templateUrl: './pagination-controller.component.html',
  styleUrls: ['./pagination-controller.component.scss'],
})
export class PaginationControllerComponent {
  @Input() limitOptions = [20, 30, 40, 50];

  constructor(@Inject(PAGINATOR) public pn: Paginator) {}

  changeLimit(ev: any) {
    this.changePage(1, parseInt(ev?.target?.value));
  }

  changePage(pageNo: number, limit: number) {
    this.pn.$pagination.next({ limit, pageNo });
  }
}
