import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeService } from '../theme.service';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GenericFilterPipe } from './pipes/generic-filter.pipe';
import { PaginationControllerComponent } from './components/pagination-controller/pagination-controller.component';
import { PaginationWrapperComponent } from './components/pagination-wrapper/pagination-wrapper.component';
import { CopyToClipboardDirective } from './pipes/copy-to-clipboard.directive';
import { CopyBtnComponent } from './copy-btn/copy-btn.component';
@NgModule({
  declarations: [
    SidebarComponent,
    GenericFilterPipe,
    PaginationControllerComponent,
    PaginationWrapperComponent,
    CopyToClipboardDirective,
    CopyBtnComponent,
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatListModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    FormsModule,
  ],
  exports: [
    SidebarComponent,
    GenericFilterPipe,
    PaginationControllerComponent,
    PaginationWrapperComponent,
    CopyToClipboardDirective,
    CopyBtnComponent,
  ],
  providers: [ThemeService],
})
export class CoreModule {}
