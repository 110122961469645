import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { DffService } from './dff/dff.service';
import { FAYE_CLIENT } from './core/tokens/fayeClient.token';

declare var require: any;
const faye = require('faye');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'RFT-WebApp';
  bytes: any;
  permissions: any;
  envText: any;
  currentSeasonYear: any;
  authenticate: boolean = false;
  fayeSubscriptions: any[] = [];

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private auth: AuthService,
    public _authService: AuthService,
    private toastr: ToastrService,
    private dffService: DffService,
    @Inject(FAYE_CLIENT) private fayeClient: any
  ) {
    this.fayeImplementation();
  }

  ngOnInit(): void {
    this.checkEnv();
    this.getSeason();
  }

  ngDoCheck() {
    this.isLoggedIn();
    this._changeDetectorRef.detectChanges();
  }

  isLoggedIn() {
    if (this.auth.isLogin()) {
      this.authenticate = true;
    } else {
      this.authenticate = false;
    }
  }

  public getSeason() {
    this.dffService.getCurrentSeason().subscribe(
      (res) => {
        this.currentSeasonYear = res.data?.items[0]?.season;
      },
      (error) => {
        // this.toastr.error(error.message, 'RFT NOTIFICATION');
      }
    );
  }

  fayeImplementation() {
    const subscriptionOne = this.fayeClient.subscribe(
      '/LiveFeed',
      (msg: any) => {
        if (msg?.match?.penaltyScore.homeTeamScore == null) {
          this.toastr.info(
            '<div class="row"><div class="col-6 pe-0 text-secondary">' +
              msg?.match?.hTeamName +
              '</div><div class="col-6 ps-0 text-end  text-secondary">' +
              msg?.match?.aTeamName +
              '</div></div><div class="row"><div class="col-5 pe-0"><img height=30px src="https://s3.amazonaws.com/realfantasyteams/uploads/team_logo/' +
              msg?.match?.hTeamRef +
              '.png">' +
              ' ' +
              '  ' +
              msg?.match?.hTeamCode +
              '</div><div class="col-2 text-center p-0"> ' +
              msg?.match?.hTeamScore +
              ' ' +
              ' : ' +
              ' ' +
              msg?.match?.aTeamScore +
              '</div><div class="col-5 text-end ps-0">  ' +
              msg?.match?.aTeamCode +
              ' <img  height=30px src="https://s3.amazonaws.com/realfantasyteams/uploads/team_logo/' +
              msg?.match?.aTeamRef +
              '.png"></div></div><div class="row"><div class="col-12 text-center">' +
              msg?.match?.timings?.min +
              '"</div></div>',
            '',
            {
              closeButton: false,
              progressBar: true,
              enableHtml: true,
              onActivateTick: false,
              toastClass: 'toaster-container-custom ngx-toastr',
            }
          );
        } else {
          this.toastr.info(
            '<div class="row"><div class="col-6 pe-0 text-secondary">' +
              msg?.match?.hTeamName +
              '</div><div class="col-6 ps-0 text-end  text-secondary">' +
              msg?.match?.aTeamName +
              '</div></div><div class="row"><div class="col-5 pe-0"><img height=30px src="https://s3.amazonaws.com/realfantasyteams/uploads/team_logo/' +
              msg?.match?.hTeamRef +
              '.png">' +
              ' ' +
              '  ' +
              msg?.match?.hTeamCode +
              '</div><div class="col-2 text-center p-0"> ' +
              msg?.match?.hTeamScore +
              ' ' +
              ' : ' +
              ' ' +
              msg?.match?.aTeamScore +
              '</div><div class= "col-5 text-end ps-0"> ' +
              msg?.match?.aTeamCode +
              ' <img  height=30px src="https://s3.amazonaws.com/realfantasyteams/uploads/team_logo/' +
              msg?.match?.aTeamRef +
              '.png"></div></div><div class="row"><div class="col-12 text-center p-0">( PEN: ' +
              msg?.match?.penaltyScore?.homeTeamScore +
              ' ' +
              ' : ' +
              ' ' +
              msg?.match?.penaltyScore?.awayTeamScore +
              ' )</div></div><div class="row"><div class="col-12 text-center" > ' +
              msg?.match?.timings?.min +
              '"</div></div>',
            '',
            {
              closeButton: false,
              progressBar: true,
              enableHtml: true,
              onActivateTick: false,
              toastClass: 'toaster-container-custom ngx-toastr',
            }
          );
        }
      }
    );

    const subscriptionTwo = this.fayeClient.subscribe(
      '/PlayerUpdate',
      (msg: any) => {
        this.toastr.info('Player Update Pulled!', 'RFT NOTIFICATION');
      }
    );

    const subscriptionThree = this.fayeClient.subscribe(
      '/Fixture',
      (msg: any) => {
        this.toastr.warning('Fixture Pulled!', 'RFT NOTIFICATION');
      }
    );

    this.fayeSubscriptions.push(
      subscriptionOne,
      subscriptionTwo,
      subscriptionThree
    );
  }
  public checkEnv() {
    if (
      environment.hostUrl ==
      'https://devadminapi.realfantasyteams.com/adminService'
    ) {
      this.envText = 'Development';
    }
    if (
      environment.hostUrl ==
      'https://adminapi.realfantasyteams.com/adminService'
    ) {
      this.envText = 'Production';
    }
    if (
      environment.hostUrl ==
      'https://stagingadminapi.realfantasyteams.com/adminService'
    ) {
      this.envText = 'Staging';
    }
  }

  ngOnDestroy(): void {
    this.fayeSubscriptions?.forEach((sub) => sub?.unsubscribe());
  }
}
